import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import NewsLoop from "../components/newsLoop"
import SEO from '../components/seo'
import Pagenation from '../components/pagination'
// import { NewsListWrapper } from "../style/common/common"
import { NewsListWrapper } from '../style/common/common'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const NewsArchive = ({ data, location, pageContext }) => {
  const title = "お知らせ"
  const NewsArchivePosts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map((edge, index) => <NewsLoop post={edge.node} key={index}/>)
  // const { breadcrumb: { crumbs } } = pageContext
  const crumbs = [
    {
      pathname: '/',
      crumbLabel: 'トップ'
    },
    {
      pathname: '/news',
      crumbLabel: 'お知らせ'
    }
  ]

  return (
    <Layout
      location={location}
      title={title}
    >
      <SEO
        title={`${title} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        // crumbLabel="お知らせ"
      />
      <div className="BookListHeader">
        {/* <h1>{title}</h1> */}
      </div>
      <NewsListWrapper>
        {NewsArchivePosts}
      </NewsListWrapper>
      <Pagenation pageContext={pageContext} />
      {/* <Pagination>
        {Array.from({ length: numPages }, (_, i) => (
          <li key={`pagination-number${i + 1}`}>
            <Link to={`/news/${i === 0 ? "" : i + 1}`}>{i + 1}</Link>
          </li>
        ))}
      </Pagination> */}
    </Layout>
  )
}

export default NewsArchive

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {posttype: {eq: "news"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            category
            thumb {
              id
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
